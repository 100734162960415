import * as React from "react";
import {
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBBtn,
	MDBCard,
	MDBCardBody,
	MDBTable,
	MDBTableHead,
	MDBTableBody,
} from "mdbreact";
import SEO from "../components/SEO/Seo";
import AnimatedHeader from "../components/Headers/AnimatedHeader/AnimatedHeader";

import Layout from "../Layouts/MasterLayout/MasterLayout";
//import "../components/Css/descontos.css"
// header
import HeaderBackground from "../assets/images/vantagens/headerBackground.png";
import HeaderBalloon from "../assets/images/vantagens/headerBalloon.png";
import HeaderBalloonSmall from "../assets/images/vantagens/headerBalloon.png";
import HeaderBackgroundSmall from "../assets/images/vantagens/headerBackgroundMobile.png";

//import OportunidadesImage from "../assets/images/vantagens/oportunidadesFeevale.png";

let HeaderBallonProp = (
	<img
		src={HeaderBalloon}
		alt="Conheça as vantagens para ingressar na Universidade Feevale. Bolsas e descontos."
		className="img-fluid headerballon"
	/>
);

let HeaderBallonPropSmall = (
	<img
		src={HeaderBalloonSmall}
		alt="Conheça as vantagens para ingressar na Universidade Feevale. Bolsas e descontos."
		className="img-fluid"
	/>
);

export default function DescontosPage() {
	return (
		<Layout color="#ebebeb">
			<SEO
				title="Vantagens para ingressar"
				description="Conheça as vantagens para ingressar na Universidade Feevale. Bolsas e descontos."
			/>

			{/* <AnimatedHeader contentA={HeaderBallonProp} image={HeaderBackground} /> */}
			<AnimatedHeader contentA={HeaderBallonProp} contentB={HeaderBallonPropSmall} image={HeaderBackground} imageb={HeaderBackgroundSmall}/>
			<MDBContainer className="mt-5">
				<MDBRow className="mb-4">
					<MDBCol>
						<h1 className="text-default h1-responsive font-weight-bold">
							Bolsas e descontos
						</h1>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					{/*<MDBCol xl="12" className="mb-2">
						<MDBCard>
							<MDBCardBody>
								<h4>DESCONTOS PARA CURSOS PRESENCIAIS</h4>
								<p>
									A matrícula de alunos com ingresso a partir de 2023/01 nos
									cursos de graduação presencial contratados por créditos
									possibilitará um desconto no primeiro semestre conforme os
									seguintes critérios:
								</p>
								<MDBTable striped>
									<MDBTableHead color="secondary-color" textWhite>
										<tr className="text-center">
											<td className="font-weight-bold">
												Número de créditos contratados
											</td>
											<td className="font-weight-bold">
												% de desconto aplicado na matrícula do 1º semestre
											</td>
										</tr>
									</MDBTableHead>
									<MDBTableBody>
										<tr className="text-center">
											<td>12 a 15 créditos</td>
											<td>4%</td>
										</tr>
										<tr className="text-center">
											<td>16 a 19 créditos</td>
											<td>8%</td>
										</tr>
										<tr className="text-center">
											<td>20 créditos ou +</td>
											<td>12%</td>
										</tr>
									</MDBTableBody>
								</MDBTable>
								<p>
									Observação: Este desconto não se aplica aos cursos contratados
									por mensalidade, por módulo, seriados, cursos na modalidade
									digital e também aos cursos de Medicina, Medicina Veterinária
									e Odontologia.
								</p>
							</MDBCardBody>
						</MDBCard>
	</MDBCol>*/}
					
					<MDBCol xl="12" className="my-2">
						<MDBCard className="default-color text-primary">
							<MDBCardBody>
								<h4>EXTRAVESTIBULAR</h4>
								<p>
								Descontos destinados à transferência de curso, reingresso, portadores de diploma, estudantes evadidos e Programa 35+.
								</p>
								<MDBBtn
									href="https://conteudo.feevale.br/extravestibular24"
									color="primary"
									style={{color:"#303030"}}
									target="blank">
									Acesse
								</MDBBtn>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
					<MDBCol xl="6" className="my-2 d-flex">
						<MDBCard className="default-color text-primary">
							<MDBCardBody>
								<h4>PARA SERVIDORES PÚBLICOS</h4>
								<p>
									25% de desconto para os cursos contratados por crédito ou 15% de desconto
									para os cursos contratados por módulos. Destinado aos cursos de graduação
									para funcionários das seguintes corporações e que tenham firmado convênio de parceria
									com a Instituição:
								</p>
								<ul>
									<li>Brigada Militar</li>
									<li>Polícia Civil</li>
									<li>Corpo de Bombeiros</li>
									<li>Guarda Municipal</li>
								</ul>								
								<MDBBtn
									href="https://www.feevale.br/institucional/atendimento-feevale"
									color="primary"
									style={{color:"#303030"}}
									target="blank">
									Mais informações
								</MDBBtn>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
					<MDBCol xl="6" className="my-2 d-flex">
						<MDBCard className="default-color text-primary">
							<MDBCardBody>
								<h4>PARA EDUCADORES</h4>
								<p>
								    40% de desconto para os cursos contratados por crédito ou 30% de
								    desconto para os cursos contratados por módulos. Destinado aos cursos 
									licenciatura para professores sem formação superior, vinculados à Secretaria
									Estadual de Educação do Estado do Rio Grande do Sul, por meio
									das Cordenadorias Regionais de Educação (CREs), e às
									Secretarias Municipais de Educação (SMEDs) dos municípios da
									região que firmarem parceria com a Universidade.
									<br/><br/><br/>
								</p>																
								<MDBBtn
									href="https://www.feevale.br/institucional/atendimento-feevale"
									color="primary"
									style={{color:"#303030"}}
									target="blank">
									Mais informações
								</MDBBtn>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
					<MDBCol xl="12" className="my-2">
						<MDBCard className="default-color text-primary">
							<MDBCardBody>
								<h4>INGRESSE PELO PROUNI</h4>
								<p>
									A Universidade Feevale oferece oportunidades de ingresso
									através do PROUNI.
								</p>
								<MDBBtn
									href="https://www.feevale.br/todas-as-oportunidades/prouni"
									color="primary"
									style={{color:"#303030"}}
									target="blank">
									Saiba mais
								</MDBBtn>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
					<MDBCol xl="6" className="my-2">
						<MDBCard className="default-color text-primary">
							<MDBCardBody>
								<h4>BOLSAS DE EXTENSÃO</h4>
								<p>
									Atue em projetos sociais em andamento na Instituição e receba
									créditos acadêmicos.
								</p>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
					<MDBCol xl="6" className="my-2">
						<MDBCard className="default-color text-primary">
							<MDBCardBody>
								<h4>BOLSAS DE INICIAÇÃO CIENTÍFICA</h4>
								<p>
									Atue em projetos de pesquisa em andamento na Instituição e
									receba créditos acadêmicos.
								</p>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
					<MDBCol xl="6" className="my-2">
						<MDBCard className="default-color text-primary">
							<MDBCardBody>
								<h4>MONITORIAS</h4>
								<p>
									Atue com monitoria em disciplinas e receba créditos
									acadêmicos.
								</p>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
					<MDBCol xl="12" className="my-2">
						<MDBCard className="default-color text-primary">
							<MDBCardBody>
								<h4>BOLSA POR PERDA DE EMPREGO</h4>
								<p>
									Bolsa que garante a continuidade do pagamento das mensalidades
									para os alunos matriculados na Instituição que foram
									dispensados sem justa causa do emprego. A bolsa é limitada ao
									valor do salário, pelo período de até três meses. Para mais
									informações, consulte as regras e critérios de concessão do
									benefício, no Guia Acadêmico.
								</p>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
				<MDBRow className="mt-4">
					<MDBCol>
						{/* <a
							href="https://www.feevale.br/oportunidades"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={OportunidadesImage}
								alt="Oportunidades Feevale"
								className="img-fluid my-2"
							/>
						</a> */}

						<p style={{color:"#303030"}}>
							Para ajudar a manter seus estudos, ao ingressar na Universidade
							Feevale você conta com um portal exclusivo para graduando(a)
							repleto de vagas de emprego e estágios e também outras bolsas.
							Toda semana surgem novas oportunidades e você pode candidatar-se.
						</p>

						<MDBBtn
							href="https://www.feevale.br/oportunidades"
							color="default"
							block
							target="blank">
							Acesse
						</MDBBtn>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
